<template>
  <div class="rtl">
    <v-card>
      <v-card-title>
        مدیریت کاربران
        <v-spacer></v-spacer>
        <v-text-field class="searchStyle" v-model="search" append-icon="mdi-magnify" label="جست و جو" single-line hide-details></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="userListForAdmin" :search="search">
        <template v-slot:item.operations="{ item }">
          <div style="display:flex;justify-content:space-around">
            <router-link :to="'/documentUserManage/' + item.username">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="whiteButton btn_index iconBtn" v-bind="attrs" v-on="on">
                    <v-icon large>
                      mdi-folder-open
                    </v-icon>
                  </v-btn>

                </template>
                <span style="color:white">پرونده</span>
              </v-tooltip>
            </router-link>
            <router-link :to="'/turnOverUser/' + item.username">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="whiteButton btn_index iconBtn" v-bind="attrs" @click="$root.choosedUser = item.username" v-on="on">
                    <v-icon large>
                      mdi-clipboard-text-clock-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span style="color:white">سوابق مالی</span>
              </v-tooltip>
            </router-link>
            <router-link to="/securityUser">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="whiteButton btn_index iconBtn" v-bind="attrs" @click="$root.choosedUser = item.username" v-on="on">
                    <v-icon large>
                      mdi-shield-account-outline
                    </v-icon>
                  </v-btn>

                </template>
                <span style="color:white">امنیت</span>
              </v-tooltip>
            </router-link>
            <router-link to="/userMessages">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="whiteButton btn_index iconBtn" v-bind="attrs" @click="$root.choosedUser = item.username" v-on="on">
                    <v-icon large>
                      mdi-message-settings-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span style="color:white">پیام ها</span>
              </v-tooltip>
            </router-link>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>

</template>

<script>
import axiosApi from "@/axios";
// import Vue from "vue";

export default {
  components: {
  },
  name: "UserManage",
  data() {
    return {
      userListForAdmin: [],
      userManageSearchQuery: '',
      userManageSearchShow: false,
      usersSearchShow: false,
      headers: [
        {
          text: 'نام ',
          align: 'start',
          sortable: false,
          value: 'firstName',
        },
        { text: 'نام خوانوادگی', value: 'lastName' },
        { text: 'کد ملی', value: 'nationalId' },
        { text: 'شناسه کاربری', value: 'username' },
        // {text: 'ایمیل', value: 'email'},
        { text: 'عملیات', value: 'operations' },
      ],
      search: ''

    };
  },
  props: [

  ]
  ,
  methods: {
    getUsersAndStatus() {
      axiosApi().post('/api/Manage/v1/User/GetList', {})
        .then(({ data }) => {
          if (data['isSuccess'])
            this.userListForAdmin = data.data;
        },)
        .catch(() => {

        });
    },
    userManageSearchResult() {
      if (this.userManageSearchQuery) {
        return this.userListForAdmin.filter((item) => {
          return (
            this.userManageSearchQuery.toLowerCase().split(' ').every(v => { return ((item.email !== null ? item.email.toLowerCase().includes(v) : false) || item.userName.toString().includes(v)); })
          );
        });
      } else {
        return this.userListForAdmin;
      }
    },
    bluringUserSearchInput() {
      setTimeout(() => {
        this.usersSearchShow = false;
      }, 150);
    },
  },
  mounted() {
    this.getUsersAndStatus();
  }
}


</script>

<style scoped>

</style>
